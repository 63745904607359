
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import dialog from '@/vuex/dialog';

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() value: any;
  @Prop({default: false}) multi: boolean;
  @Prop({default: 'null'}) this_value: any;
  @Prop({default: '無効'}) invalidstring: string;
  @Prop({default: '有効'}) validstring: string;
  @Prop({default: '#999999'}) invalidcolor: string;
  @Prop({default: '#4da6ff'}) validcolor: string;
  @Prop({default: false}) disabled: boolean | boolean[];
  @Prop() color: string;
  @Prop() disable_reason: any;
  

  once_input: boolean = false;//lazy
  
  get use_disabled() {
    if (Array.isArray(this.disabled)) {
      var disabled = false;
      this.disabled.forEach(now => {
        if (now) {
          disabled = true;
        }
      });
      return disabled;
    } else {
      return this.disabled;
    }
  }

  get checked_value() {
    if (this.multi && typeof this.value !== 'boolean') {
      return this.value.indexOf(this.this_value) !== -1;
    } else {
      return this.value;
    }
  }
  set checked_value(val) {
    this.change_checked_value(val);
    this.once_input = true;
  }

  @Emit('input')
  change_checked_value(val) {
    if (this.multi && Array.isArray(this.value)) {
      if (val) {
        var tmp = this.value;
        tmp.push(this.this_value);
        return tmp;
      } else {
        return this.value.filter(value => value != this.this_value);
      }
    } else {
      return val;
    }
  }

  get styles() {
    return {
      '--color': this.color ?? '#00a0e9',
      '--invalidstring': this.invalidstring ?? '無効',
      '--validstring': this.validstring ?? '有効',
      '--invalidcolor': this.invalidcolor ?? '#999999',
      '--validcolor': this.validcolor ?? '#4da6ff',
    }
  }

  get genid() {
    return uuidv4();
  }

  disable_error() {
    var str = [];

    if (Array.isArray(this.disabled)) {
      this.disabled.forEach((now, i) => {
        if (now) {
          str = str.concat(this.disable_reason[i]);
        }
      });
    } else {
      str = this.disable_reason;
    }
    dialog.openErrorDialog({error: str});
  }
}
